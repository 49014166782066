export const LARAVEL_IMPORT_YAML = `#yamlPreprocessor=on
project:
  name: recipe-laravel
  tags:
    - zerops-recipe

services:
  - hostname: app
    type: php-nginx@8.3
    buildFromGit: https://github.com/zeropsio/recipe-laravel-jetstream
    enableSubdomainAccess: true
    envSecrets:
      APP_NAME: ZeropsLaravel
      APP_DEBUG: true
      APP_ENV: production
      APP_KEY: <@generateRandomString(<32>)>

  - hostname: db
    type: postgresql@16
    mode: NON_HA
    priority: 10

  - hostname: redis
    type: valkey@7.2
    mode: NON_HA
    priority: 10

  - hostname: storage
    type: object-storage
    objectStorageSize: 2
    objectStoragePolicy: public-read
    priority: 10

  - hostname: mailpit
    type: go@1
    buildFromGit: https://github.com/zeropsio/recipe-mailpit
    enableSubdomainAccess: true

  - hostname: adminer
    type: php-apache@8.3
    buildFromGit: https://github.com/zeropsio/recipe-adminer@main
    enableSubdomainAccess: true`;
