import { Component, Input, Output, EventEmitter, HostBinding, ChangeDetectionStrategy } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Notification } from '@zerops/models/notification';
import { ServiceStackTypeCategories, ServiceStack } from '@zerops/models/service-stack';
import { AppVersionItemTranslations } from '@zerops/zui/app-version-item';
import { AppVersion, AppVersionStatuses, ZeropsYamlErrors } from '@zerops/models/app-version';
import { NotificationItemTranslations } from './notification-item.translations';
import { PipelineError } from '@zerops/models/error-backend';

type ActionsMap = Record<
  string,
  {
    isSystem?: boolean;
    disabledLog?: boolean;
    disableDuration?: boolean;
  }
>;

@Component({
  selector: 'zui-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: [ './notification-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationItemComponent {

  actionsMap: ActionsMap = {
    'APPLICATION_EVENT_PORT_CHECK_FAILED': {
      isSystem: true,
      disabledLog: true,
      disableDuration: true
    },
    'APPLICATION_EVENT_FAIL_AND_RESTART': {
      isSystem: true,
      disabledLog: true,
      disableDuration: true
    },
    'dnsCheckFailed': {
      disabledLog: true,
      disableDuration: true
    },
    'instance.create': {
      isSystem: true,
      disabledLog: true
    },
    'instance.delete': {
      isSystem: true,
      disabledLog: true
    },
    'instance.stop': {
      disabledLog: true
    },
    'instance.start': {
      disabledLog: true
    },
    'instanceGroup.create': {
      isSystem: true,
      disabledLog: true
    },
    'instanceGroup.delete': {
      isSystem: true,
      disabledLog: true
    },
    'instance.createL7HttpBalancerStack': {
      isSystem: true,
      disabledLog: true
    },
    'instance.deleteL7HttpBalancerStack': {
      isSystem: true,
      disabledLog: true
    },
    'instance.syncPublicHttpRoutingAndCreateL7HttpBalancerStack': {
      isSystem: true,
      disabledLog: true
    },
    'instance.syncPublicPortRouting': {
      disabledLog: true
    },
    'publicIpRequest.request': {
      disabledLog: true,
      disableDuration: true
    },
    'publicIpRequest.release.SUCCESS': {
      disabledLog: true,
      disableDuration: true
    },
    'stack.updatePorts': {
      disabledLog: true
    },
    'stack.update': {
      disabledLog: true
    },
    'stack.enableSubdomainAccess': {
      disabledLog: true
    },
    'stack.disableSubdomainAccess': {
      disabledLog: true
    },
    'stack.updateAutoscaling': {
      disabledLog: true
    },
    'stack.delete.disconnectSharedStorageConnections': {
      isSystem: true,
      disabledLog: true
    },
    'stack.delete': {
      disabledLog: true
    },
    'stack.start': {
      disabledLog: true
    },
    'stack.create': {
      disabledLog: false
    },
    'stack.stop': {
      disabledLog: true,
      disableDuration: true
    },
    'stack.restart': {
      disabledLog: true
    },
    'stack.reload': {
      disabledLog: true
    },
    'zbilling.addonInsufficientFunds': {
      disabledLog: true,
      disableDuration: true
    },
    'zbilling.subscriptionRenewalFailed': {
      disabledLog: true,
      disableDuration: true
    },
    'zbilling.projectMandatoryMissingStop': {
      disabledLog: true,
      disableDuration: true
    },
    'zbilling.syncPublicHttpRouting': {
      disabledLog: true
    },
    'zbilling.syncPublicPortRouting': {
      disabledLog: true,
    },
    'stack.sharedStorageDisconnect': {
      disabledLog: true
    },
    'zbilling.addonCountExceeded': {
      disableDuration: true
    },
    'zbilling.projectMandatoryMissing': {
      disableDuration: true
    },
    'zbilling.paymentCardExpired': {
      disableDuration: true,
    },
    'zbilling.paymentCardExpiring':{
      disableDuration: true
    },
    'zbilling.paymentCardNotFound': {
      disableDuration: true
    },
    'zbilling.projectStop': {
      disableDuration: true
    },
    'zbilling.projectStart': {
      disableDuration: true
    },
    'zbilling.subscriptionDeactivated': {
      disableDuration: true
    },
    'zbilling.subscriptionActivated': {
      disableDuration: true
    },
    'zbilling.subscriptionRegistered': {
      disableDuration: true
    },
    'zbilling.subscriptionRenewalSuccess': {
      disableDuration: true
    },
    'zbilling.projectShutdownNotification': {
      disableDuration: true
    },
    'zbilling.topup': {
      disableDuration: true
    },
  };
  typeColorMap = {
    WARNING: 'orange',
    ERROR: 'red'
  };
  buildServiceStack: ServiceStack;
  appVersionStatuses = AppVersionStatuses;
  serviceStackTypeCategories = ServiceStackTypeCategories;
  zeropsYamlErrors = ZeropsYamlErrors;
  noPipelineLinkDetail = false;

  private _showMarkAsRead = false;
  private _hideUnread = false;
  private _data: Notification;

  @Input()
  set data(v) {
    /**
     * This is a special case when the appVersion object is not created on the background at all.
     * It means also that it is not possible to display a related pipeline detail page.
     * Because of the fact the link to a pipeline detail should disabled in a notification card description.
     */
    if (v?.error?.code === ZeropsYamlErrors.ZeropsYamlSetupNotFound) {
      this.noPipelineLinkDetail = true;
    }
    this.buildServiceStack = v.serviceStacks.find(
      (d) => d.serviceStackTypeInfo.serviceStackTypeCategory === ServiceStackTypeCategories.Build
    );

    this._data = {
      ...v,
      project: {
        ...v.project,
        name: v.project?.name ? this._escapeHtml(v.project.name) : ''
      },
      serviceStacks: v.serviceStacks.filter(((d) => d.serviceStackTypeInfo.serviceStackTypeCategory !== ServiceStackTypeCategories.Build))
    };
  }
  get data() {
    return this._data;
  }

  @Input()
  translations: NotificationItemTranslations;

  @Input()
  appVersionItemTranslations: AppVersionItemTranslations;

  @Input()
  markAsReadLoading = false;

  @Input()
  recent = false;

  @Input()
  set showMarkAsRead(v: string | boolean) {
    this._showMarkAsRead = coerceBooleanProperty(v);
  }
  get showMarkAsRead(): boolean {
    return this._showMarkAsRead;
  }

  @Input()
  set hideUnread(v: string | boolean) {
    this._hideUnread = coerceBooleanProperty(v);
  }
  get hideUnread(): boolean {
    return this._hideUnread;
  }

  @Input()
  color: any;

  @Input()
  disableLink = false;

  @Output()
  markAsRead = new EventEmitter<string>();

  @Output()
  internalLinkClicked = new EventEmitter<void>();

  @Output()
  pipelineDetailClicked = new EventEmitter<{ appVersion: AppVersion; pipelineErrors: PipelineError[]; }>();

  @Output()
  logClicked = new EventEmitter<{
    serviceStackId: string;
    projectId: string;
    containerId: string;
    from: string;
    to: string;
  }>();

  @HostBinding('class.is-mark-as-read-loading')
  get classIsLoading() {
    return this.markAsReadLoading;
  }

  private _escapeHtml(unsafe: string): string {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }
}
