export const PAYLOAD_IMPORT_YAML = `
#yamlPreprocessor=on
project:
  name: recipe-payload
  tags:
    - zerops-recipe
services:
  - hostname: db
    type: postgresql@16
    mode: NON_HA
    priority: 1

  - hostname: storage
    type: object-storage
    objectStorageSize: 1
    objectStoragePolicy: private
    priority: 1

  - hostname: mailpit
    type: go@1
    buildFromGit: https://github.com/zeropsio/recipe-mailpit@main
    enableSubdomainAccess: true
    priority: 1
    maxContainers: 1

  - hostname: api
    type: nodejs@20
    buildFromGit: https://github.com/zeropsio/recipe-payload@main
    enableSubdomainAccess: true
    envSecrets:
      PAYLOAD_SECRET: <@generateRandomString(<24>)>
    verticalAutoscaling:
      minRam: 1
    maxContainers: 1
`;
