export const LARAVEL_MINIMAL_PROD_IMPORT_YAML = `#yamlPreprocessor=on
project:
  name: laravel-minimal-prod
  corePackage: SERIOUS
  tags:
    - zerops-recipe
    - production

services:
  - hostname: app
    type: php-nginx@8.4
    buildFromGit: https://github.com/zeropsio/recipe-laravel-minimal
    enableSubdomainAccess: true
    minContainers: 2
    envSecrets:
      APP_KEY: <@generateRandomString(<32>)>
      APP_DEBUG: false
      APP_ENV: production

  - hostname: db
    type: postgresql@16
    mode: HA
    priority: 10`;
