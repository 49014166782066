export const nodejsZeropsYml = (service: string, serviceBuildBase: string) => {
  return `zerops:
  - setup: ${service}
    # ==== how to build your application ====
    build:
      # what technologies should the build
      # container be based on (can be an array)
      base: ${serviceBuildBase}

      # what commands to use to build your app
      buildCommands:
        - npm i
        - npm run build

      # select which files / folders to deploy
      # after the build successfully finished
      deployFiles:
        - dist
        - package.json
        - node_modules

      # *optional*: which files / folders
      # to cache for the next build run
      cache:
        - node_modules
        - package-lock.json

    # ==== how to run your application ====
    run:
      # what technology should the runtime
      # container be based on, can be extended
      # in \`run.prepareCommands\` using
      # \`zsc install nodejs@20\`
      base: ${serviceBuildBase}

      # what ports your app listens on
      # and whether it supports http traffic
      ports:
        - port: 3000
          httpSupport: true

      # how to start your application
      start: npm start`
};

export const bunZeropsYml = (service: string, serviceBuildBase: string) => {
  return `zerops:
  - setup: ${service}
    # ==== how to build your application ====
    build:
      # what technologies should the build
      # container be based on (can be an array)
      base: ${serviceBuildBase}

      # what commands to use to build your app
      buildCommands:
        - bun install
        - bun run build

      # select which files / folders to deploy
      # after the build successfully finished
      deployFiles:
        - dist
        - package.json
        - node_modules

      # *optional*: which files / folders
      # to cache for the next build run
      cache:
        - node_modules
        - bun.lockb

    # ==== how to run your application ====
    run:
      # what technology should the runtime
      # container be based on, can be extended
      # in \`run.prepareCommands\` using
      # \`zsc install nodejs@20\`
      base: ${serviceBuildBase}

      # what ports your app listens on
      # and whether it supports http traffic
      ports:
        - port: 3000
          httpSupport: true

      # how to start your application
      start: bun start`
};

export const denoZeropsYml = (service: string, serviceBuildBase: string) => {
  return `zerops:
  - setup: ${service}
    # ==== how to build your application ====
    build:
      # what technologies should the build
      # container be based on (can be an array)
      base: ${serviceBuildBase}

      # what commands to use to build your app
      buildCommands:
        - deno task build

      # select which files / folders to deploy
      # after the build successfully finished
      deployFiles:
        - dist
        - deno.jsonc

    # ==== how to run your application ====
    run:
      # what technology should the runtime
      # container be based on, can be extended
      # in \`run.prepareCommands\` using
      # \`zsc install nodejs@20\`
      base: ${serviceBuildBase}

      # what ports your app listens on
      # and whether it supports http traffic
      ports:
        - port: 8000
          httpSupport: true

      # how to start your application
      start: deno task start`
};

export const javaZeropsYml = (service: string, serviceBuildBase: string) => {
  return `zerops:
  - setup: ${service}
    # ==== how to build your application ====
    build:
      # what technologies should the build
      # container be based on (can be an array)
      base: ${serviceBuildBase}

      # what commands to use to build your app
      buildCommands:
        - ./mvnw clean install

      # select which files / folders to deploy
      # after the build successfully finished
      deployFiles:
        - target/app.jar

    # ==== how to run your application ====
    run:
      # what technology should the runtime
      # container be based on, can be extended
      # in \`run.prepareCommands\` using
      # \`zsc install nodejs@20\`
      base: ${serviceBuildBase}

      # what ports your app listens on
      # and whether it supports http traffic
      ports:
        - port: 8080
          httpSupport: true

      # how to start your application
      start: java -jar target/app.jar`
};

export const ubuntuZeropsYml = (service: string, serviceBuildBase: string) => {
  return `zerops:
  # supports monorepositories, just add
  # one setup section after the other
  - setup: ${service}
    # ==== how to build your application ====
    build:
      # select what technology should the
      # build container be based on, can
      # be different from service technology
      # see docs for full list
      base: ${serviceBuildBase}

      # *optional* add additional dependencies
      # on top of base technology (combination
      # will be cached for next build)
      # prepareCommands:
      #   - apt-get something

      # build your application
      buildCommands:
        - echo 'while true; do echo "running"; sleep 15; done' > run.sh
        - chmod +x run.sh

      # select which files / folders to deploy
      # after the build successfully finished
      deployFiles:
        - run.sh

      # *optional*: which files / folders
      # to cache for the next use
      # cache:

    # ==== how to run your application ====
    run:
      base: ${serviceBuildBase}

      # *optional* install dependencies that your
      # runtime service requires (will be cached)
      # prepareCommands:
      #   - apt-get something

      # *optional*: triggered before each start/restart
      # of your application, to eg. clean cache
      # initCommands:
      #  - rm -rf ./cache

      # how to start your application
      start: ./run.sh`
};

export const alpineZeropsYml = (service: string, serviceBuildBase: string) => {
  return `zerops:
  # supports monorepositories, just add
  # one setup section after the other
  - setup: ${service}
    # ==== how to build your application ====
    build:
      # select what technology should the
      # build container be based on, can
      # be different from service technology
      # see docs for full list
      base: ${serviceBuildBase}

      # *optional* add additional dependencies
      # on top of base technology (combination
      # will be cached for next build)
      # prepareCommands:
      #   - apt-get something

      # build your application
      buildCommands:
        - echo 'while true; do echo "running"; sleep 15; done' > run.sh
        - chmod +x run.sh

      # select which files / folders to deploy
      # after the build successfully finished
      deployFiles:
        - run.sh

      # *optional*: which files / folders
      # to cache for the next use
      # cache:

    # ==== how to run your application ====
    run:
      base: ${serviceBuildBase}

      # *optional* install dependencies that your
      # runtime service requires (will be cached)
      # prepareCommands:
      #   - apt-get something

      # *optional*: triggered before each start/restart
      # of your application, to eg. clean cache
      # initCommands:
      #  - rm -rf ./cache

      # how to start your application
      start: ./run.sh`
};

export const golangZeropsYml = (service: string, serviceBuildBase: string) => {
  return `zerops:
  - setup: ${service}
    # ==== how to build your application ====
    build:
      # what technologies should the build
      # container be based on (can be an array)
      base: ${serviceBuildBase}

      # build your application
      buildCommands:
        - go build -o app main.go

      # select which files / folders to deploy
      # after the build successfully finished
      deployFiles: app

    # ==== how to run your application ====
    run:
      # what technology should the runtime
      # container be based on, can be extended
      # in \`run.prepareCommands\` using
      # \`zsc install nodejs@20\`
      base: ${serviceBuildBase}

      # what ports your app listens on
      # and whether it supports http traffic
      ports:
        - port: 8080
          httpSupport: true

      # how to start your application
      start: ./app`
};

export const elixirZeropsYml = (service: string, serviceBuildBase: string) => {
  return `zerops:
  - setup: ${service}
    # ==== how to build your application ====
    build:
      # what technologies should the build
      # container be based on (can be an array)
      base: ${serviceBuildBase}

      # build environment variables
      envVariables:
        MIX_ENV: prod

      # build your application
      buildCommands:
        - mix deps.get
        - mix compile
        - mix release

      # select which files / folders to deploy
      # after the build successfully finished
      deployFiles: _build/prod/rel/app/~

    # ==== how to run your application ====
    run:
      # what technology should the runtime
      # container be based on, can be extended
      # in \`run.prepareCommands\` using
      # \`zsc install nodejs@20\`
      base: alpine@latest

      # what ports your app listens on
      # and whether it supports http traffic
      ports:
        - port: 4000
          httpSupport: true

      # how to start your application
      start: bin/app start`
};

export const gleamZeropsYml = (service: string, serviceBuildBase: string) => {
  return `zerops:
  - setup: ${service}
    # ==== how to build your application ====
    build:
      # what technologies should the build
      # container be based on (can be an array)
      base: ${serviceBuildBase}

      # build your application
      buildCommands:
        - gleam export erlang-shipment

      # select which files / folders to deploy
      # after the build successfully finished
      deployFiles:
        - build/erlang-shipment/~

    # ==== how to run your application ====
    run:
      # what technology should the runtime
      # container be based on, can be extended
      # in \`run.prepareCommands\` using
      # \`zsc install nodejs@20\`
      base: ${serviceBuildBase}

      # what ports your app listens on
      # and whether it supports http traffic
      ports:
        - port: 8080
          httpSupport: true

      # how to start your application
      start: ./entrypoint.sh run`
};

export const phpApacheZeropsYml = (service: string, serviceType: string, serviceBuildBase: string) => {
  return `zerops:
  - setup: ${service}
    # ==== how to build your application ====
    build:
      # what technology should the runtime
      # container be based on, can be extended
      # in \`run.prepareCommands\` using
      # \`zsc install nodejs@20\`
      base: ${serviceBuildBase}

      # build your application
      buildCommands:
        - composer install --optimize-autoloader --no-dev

      # select which files / folders to deploy
      # after the build successfully finished
      deployFiles: ./

      # *optional*: which files / folders
      # to cache for the next use
      cache:
        - vendor
        - composer.lock

    # ==== how to run your application ====
    run:
      # what technology should the runtime
      # container be based on, can be extended
      # in \`run.prepareCommands\` using
      # \`zsc install nodejs@20\`
      base: ${serviceType}

      # where will public traffic be pointed to
      # usually where you index.php resides
      # files from deploy are placed at /var/www
      # this example would point public traffic
      # to /var/www/public
      documentRoot: public`
};

export const staticZeropsYml = (service: string) => {
  return `zerops:
  - setup: ${service}
    # ==== How to build your application ====
    build:
      # what technologies should the build
      # container be based on (can be an array)
      base: nodejs@20

      # what commands to use to build your app
      buildCommands:
        - npm i
        - npm run build my-app

      # select which files / folders to deploy
      # after the build successfully finished
      # \`~\` can be used to mark starting point
      # of deploy path, this example would deploy
      # files in the dist folder
      deployFiles:
        - dist/~

      # *optional*: which files / folders
      # to cache for the next build run
      cache:
        - node_modules
        - package-lock.json

    # ==== How to run your application ====
    run:
      base: static

      # *optional*: setup redirects for
      # SPA applications
      routing:
        redirects:
          - from: /*
            to: /index.html`
};

export const nginxStaticZeropsYml = (service: string, serviceBuildBase: string) => {
  return `zerops:
  - setup: ${service}
    # ==== how to build your application ====
    build:
      # what technologies should the build
      # container be based on (can be an array)
      base: nodejs@20

      # what commands to use to build your app
      buildCommands:
        - npm i
        - npm run build

      # select which files / folders to deploy
      # after the build successfully finished
      # \`~\` can be used to mark starting point
      # of deploy path, this example would deploy
      # files in folder dist without the folder
      # itself, either way make sure your nginx
      # config points to the correct folder
      deployFiles:
        - dist/~

      # *optional*: which files / folders
      # to cache for the next use
      cache:
        - node_modules
        - package-lock.json

    # ==== how to run your application ====
    run:
      # what technology should the runtime
      # container be based on, can be extended
      # in \`run.prepareCommands\` using
      # \`zsc install nodejs@20\`
      base: ${serviceBuildBase}

      # point to file that will contain
      # your custom nginx site config
      siteConfigPath: site.conf`
};

export const phpNginxZeropsYml = (service: string, serviceType: string, serviceBuildBase: string) => {
  return `zerops:
  - setup: ${service}
    # ==== how to build your application ====
    build:
      # what technologies should the build
      # container be based on (can be an array)
      base: ${serviceBuildBase}

      # what commands to use to build your app
      buildCommands:
        - composer install --optimize-autoloader --no-dev

      # select which files / folders to deploy
      # after the build successfully finished
      # make sure our nginx root points to the
      # correct folder, every entry of the
      # deploy parametr will be placed at /var/www
      deployFiles: ./

      # *optional*: which files / folders
      # to cache for the next use
      cache:
        - vendor
        - composer.lock

    # ==== how to run your application ====
    run:
      # what technology should the runtime
      # container be based on, can be extended
      # in \`run.prepareCommands\` using
      # \`zsc install nodejs@20\`
      base: ${serviceType}

      # point to file that will contain
      # your custom nginx site config
      siteConfigPath: site.conf`
};

export const dotnetZeropsYml = (service: string, serviceBuildBase: string) => {
  return `zerops:
  - setup: ${service}
    # ==== how to build your application ====
    build:
      # what technologies should the build
      # container be based on (can be an array)
      base: ${serviceBuildBase}

      # build your application
      buildCommands:
        - dotnet build -o app

      # select which files / folders to deploy
      # after the build successfully finished
      # \`~\` can be used to mark starting point
      # of deploy path, this example would deploy
      # files in app folder without the folder
      # itself
      deployFiles: app/~

    # ==== how to run your application ====
    run:
      # what technology should the runtime
      # container be based on, can be extended
      # in \`run.prepareCommands\` using
      # \`zsc install nodejs@20\`
      base: ${serviceBuildBase}

      # what ports your app listens on
      # and whether it supports http traffic
      ports:
        - port: 5000
          httpSupport: true

      # how to start your application
      start: dotnet dnet.dll`
};

export const rustZeropsYml = (service: string, serviceBuildBase: string) => {
  return `zerops:
  - setup: ${service}
    # ==== how to build your application ====
    build:
      # what technologies should the build
      # container be based on (can be an array)
      base: ${serviceBuildBase}

      # what commands to use to build your app
      buildCommands:
        - cargo b --release

      # select which files / folders to deploy
      # after the build successfully finished
      # \`~\` can be used to mark starting point
      # of deploy path, this example would deploy
      # just the app binary without target/release
      # folder
      deployFiles: target/release/~/app

    # ==== how to run your application ====
    run:
      # what technology should the runtime
      # container be based on, can be extended
      # in \`run.prepareCommands\` using
      # \`zsc install nodejs@20\`
      base: ${serviceBuildBase}

      # what ports your app listens on
      # and whether it supports http traffic
      ports:
        - port: 8080
          httpSupport: true

      # how to start your application
      start: ./app`
};

export const pythonZeropsYml = (service: string, serviceBuildBase: string) => {
  return `zerops:
  - setup: ${service}
    # ==== how to build your application ====
    build:
      # what technologies should the build
      # container be based on (can be an array)
      base: ${serviceBuildBase}

      # select which files / folders to deploy
      # after the build successfully finished
      # (can be an array of files)
      deployFiles: app.py

      # select which files to pass to
      # the prepare run.prepare container
      addToRunPrepare:
        - requirements.txt

    # ==== how to run your application ====
    run:
      # what technology should the runtime
      # container be based on, can be extended
      # in \`run.prepareCommands\` using
      # \`zsc install nodejs@20\`
      base: ${serviceBuildBase}

      # *optional* install dependencies that your
      # runtime service requires, this runs on a
      # separate container that prepare the final
      # runtime image, so if files source files
      # are needed here, they can be passed from
      # build using "addToRunPrepare" param
      prepareCommands:
        - python3 -m pip install -r requirements.txt --ignore-installed

      # what ports your app listens on
      # and whether it supports http traffic
      ports:
        - port: 5000
          httpSupport: true

      # how to start your application
      start: python3 app.py`
};


export const createPhpBuildBaseFromVersionType = (serviceTypeVersion: string) => {
  const subtractedVersionNum = serviceTypeVersion.substring(
    serviceTypeVersion.indexOf('@') + 1,
    serviceTypeVersion.length
  );
  const serviceBuildBase = `php@${subtractedVersionNum}`
  return serviceBuildBase;
};
