export const ELIXIR_IMPORT_YAML = `project:
  name: recipe-elixir
  tags:
    - zerops-recipe
    
services:
  - hostname: api
    type: elixir@1.16
    enableSubdomainAccess: true
    buildFromGit: https://github.com/zeropsio/recipe-elixir
    
  - hostname: db
    type: postgresql@16
    mode: NON_HA
    priority: 1`;
