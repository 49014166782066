import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { ProjectBaseApi } from '@zerops/zerops/core/project-base';
import uniqBy from 'lodash-es/uniqBy';
import orderBy from 'lodash-es/orderBy';
import {
  bufferTime,
  catchError,
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  filter,
  mergeMap,
  retry,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  timer,
  withLatestFrom
} from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import {
  TrlogState,
  LogRes,
  OpenLogStreamPayload,
  TrlogParams,
  TrLogOptions,
  TrlogUrlParams,
  LogInfoRes
} from './trlog.model';
import {
  addContainerIdToUrl,
  addTrlogParamsToUrl,
  getTrlogEndpoint,
  periodSettings
} from './trlog.utils';
import isEqual from 'lodash-es/isEqual';
import { adjustDateToMicroseconds } from '@zerops/zef/core';
import { LogData } from '@zerops/models/project';
import flatMap from 'lodash-es/flatMap';

const deepCompare = (x: any, y: any) => isEqual(x, y);

function createInitialState(): TrlogState {
  return {
    params: undefined,
    options: undefined,
    data: {
      items: undefined,
      hasMore: undefined
    },
    info: {
      items: undefined
    },
    loading: false,
    loadingInfo: false,
    loadMoreLoading: false
  };
}

@Injectable()
export class TrlogComponentStore extends ComponentStore<TrlogState> {
  private _onLoadMore$ = new Subject<'append' | 'prepend'>();

  lastPosition: number;

  readonly updateLoadingState = this.updater<boolean>((state, loading) => ({ ...state, loading }));

  readonly updateLoadingInfoState = this.updater<boolean>((state, loadingInfo) => ({ ...state, loadingInfo }));

  readonly updateLoadMoreLoadingState = this.updater<boolean>((state, loadMoreLoading) => ({ ...state, loadMoreLoading }));

  readonly updateLogData = this.updater<LogRes>((state, { items, nextPage }) => {
    const processedItems = this._processItems(items);
    return {
      ...state,
      data: {
        items: processedItems,
        hasMore: nextPage
      }
    };
  });

  readonly updateLogInfo = this.updater<LogInfoRes>((state, { items }) => {
    return {
      ...state,
      info: {
        items
      }
    };
  });

  readonly appendLogData = this.updater<{ items: LogData[]; live: boolean; }>((state, { items, live }) => {
    if (live && !state.options.live) {
      return state;
    }
    const combinedArray = uniqBy(
      [
        ...(state.data?.items || []),
        ...this._processItems(items)
      ],
      'id'
    );

    this.lastPosition = (state.data?.items.length || 0);

    return {
      ...state,
      data: {
        ...state.data,
        items: combinedArray
      }
    };
  });

  readonly prependLogData = this.updater<LogRes>((state, { items, nextPage }) => {
    const combinedArray = uniqBy(
      [
        ...this._processItems(items),
        ...(state.data?.items || [])
      ],
      'id'
    );

    this.lastPosition = combinedArray.length - (state.data?.items.length || 0);

    return {
      ...state,
      data: {
        items: combinedArray,
        hasMore: nextPage
      }
    };
  });

  readonly setParamsAndOptions = this.updater<{
    params: TrlogParams,
    options: TrLogOptions
  }>((state, { params, options }) => {
    const paramsChange = state.params ? !deepCompare(params, state.params) : true;

    return {
      ...state,
      data: paramsChange ? {
        items: [],
        hasMore: false
      } : {
        ...state.data
      },
      params: paramsChange ? params : state.params,
      options
    };
  });

  readonly setLiveState = this.updater<boolean>((state, live) => {
    return {
      ...state,
      data: {
        items: !live ? state.data.items : [],
        hasMore: state.data.hasMore
      },
      options: {
        ...state.options,
        live
      }
    };
  });

  readonly updateOptions = this.updater<Partial<TrLogOptions>>((state, options) => {
    return {
      ...state,
      options: {
        ...state.options,
        ...options
      }
    };
  });

  readonly cleanup = this.updater<void>(() => {
    return createInitialState();
  });

  get getLog$() {
    return this._getLog$;
  }

  // Effects
  private _getLog$ = this.effect<void>((_) => this.select(({ params }) => params).pipe(
    filter((d) => !!d),
    distinctUntilChanged(deepCompare),
    tap(() => this.updateLoadingState(true)),
    switchMap((d) => this._getDataStream$(d).pipe(
      tap((res) => {
        this.updateLoadingState(false);
        this.updateLogData(res);
      })
    )))
  );

  private _getLogInfo$ = this.effect<void>((_) => this.select(({ params }) => params).pipe(
    filter((d) => !!d && !!d.containerId),
    distinctUntilChanged(deepCompare),
    tap(() => this.updateLoadingInfoState(true)),
    switchMap((d) => this._getInfoStream$(d).pipe(
      tap((res) => {
        this.updateLoadingInfoState(false);
        this.updateLogInfo(res);
      })
    )))
  );

  private _loadMoreStream$ = this.effect(() => this._onLoadMore$.pipe(
    withLatestFrom(this.select((s) => s)),
    tap(() => this.updateLoadMoreLoadingState(true)),
    switchMap(([ type, state ]) => {

      let params = {
        ...state.params,
        mode: type
      };

      if (type === 'append') {
        // For append, we might set 'from' to the ID of the last item, if it exists and is within the range
        const lastId = state.data?.items[state.data?.items.length - 1]?.id;
        if (lastId && state.data?.items[state.data?.items.length - 1]?.timestamp >= state.params.from) {
          params = { ...params, from: lastId };
        }
      } else if (type === 'prepend') {
        // For prepend, we normally set 'till' to the ID of the first item
        const firstId = state.data?.items[0]?.id;
        if (firstId) {
          params = { ...params, till: firstId };
        }

        // If the timestamp of the first item is outside of the range, we set 'from' as well
        if (state.data?.items[0]?.timestamp < state.params.from) {
          params = { ...params, from: state.params.from };
        }
      }

      return this._getDataStream$(params).pipe(
        tap((res) => {
          this.updateLoadMoreLoadingState(false);
          if (type === 'prepend') {
            this.prependLogData(res);
          } else {
            this.appendLogData({
              ...res,
              live: false
            });
          }
        })
      );

    })
  ));

  private _openLogStream$ = this.effect<OpenLogStreamPayload>(() =>
    combineLatest([
      this.select(({ options }) => !!options?.live).pipe(distinctUntilChanged()),
      this.select(({ params }) => params).pipe(distinctUntilChanged(deepCompare))
    ]).pipe(
      filter(([ live ]) => live),
      withLatestFrom(this.select(({ params, data }) => ({ params, data }))),
      tap(() => this.updateLoadingState(true)),
      switchMap(([ _, { params, data } ]) =>
        this._api.logUrl$(params?.projectId).pipe(
          retry(5),
          switchMap(({ url }) =>
            webSocket<LogRes>(
              addTrlogParamsToUrl(getTrlogEndpoint(url, 'WS'), {
                serviceStackId: params?.serviceStackId,
                containerId: params?.containerId,
                projectId: params?.projectId,
                from: data?.items.length ? data[data.items.length - 1]?.id : undefined,
                limit: 100,
                minimumSeverity: params?.minimumSeverity,
                facility: !!params?.source ?
                  params.source === 'access-logs'
                    ? 17
                    : 16
                  : undefined,
                tags: params.tags
              })
            ).pipe(
              takeUntil(
                this.select(state => !!state?.options?.live).pipe(
                  distinctUntilChanged(),
                  filter(live => !live),
                  take(1)
                )
              ),
              bufferTime(100),
              tap(() => this.updateLoadingState(false)),
              switchMap((res) => {
                const items = flatMap(res, (itm) => itm.items);
                if (items.length >= 50) {
                  this.appendLogData({ items, live: true });
                  return EMPTY;
                } else {
                  const appendInterval = 1000 / items.length;
                  return items.map((item, index) => ({ item, delay: appendInterval * index }))
                }
              }),
              mergeMap(({ item, delay }) => timer(delay).pipe(
                tap(() => this.appendLogData({ items: [ item ], live: true }))
              )),
              catchError(() => EMPTY)
            )
          )
        )
      )
    )
  );

  private _processItems = (items: LogData[]): LogData[] => {
    const processedItems = items.map(d => ({
      ...d,
      timestamp: adjustDateToMicroseconds(d.timestamp)
    }));

    return orderBy(uniqBy(processedItems, 'id'), 'id', 'asc');
  };

  private _getDataStream$ = (params: TrlogParams) => {
    return this._api.logUrl$(params.projectId).pipe(
      retry(5),
      switchMap(({ url }) => this._api.logData$(
        addTrlogParamsToUrl(getTrlogEndpoint(url, 'HTTP'), this._toStateApiParams(params))
      )),
      catchError((err) => {
        this.updateLoadingState(false);
        console.error(err);
        return EMPTY;
      })
    );
  };

  private _getInfoStream$ = (params: TrlogParams) => {
    return this._api.logUrl$(params.projectId).pipe(
      retry(5),
      switchMap(({ urlInfo }) => this._api.logInfo$(
        addContainerIdToUrl(getTrlogEndpoint(urlInfo, 'HTTP'), params.containerId)
      )),
      catchError((err) => {
        this.updateLoadingInfoState(false);
        console.error(err);
        return EMPTY;
      })
    );
  };

  constructor(
    private _api: ProjectBaseApi
  ) {
    super(createInitialState());
  }

  loadMore(dir: 'append' | 'prepend' = 'prepend') {
    this._onLoadMore$.next(dir);
  }

  private _toStateApiParams(params: TrlogParams): TrlogUrlParams {
    const base = {
      // range: d.range,
      // ...rangeOptions,
      desc: params.desc === undefined ? '1' : params.desc,
      content: params.content,
      minimumSeverity: params.minimumSeverity,
      serviceStackId: params.serviceStackId,
      containerId: params.containerId,
      projectId: params.projectId,
      facility: !!params?.source ?
        params.source === 'access-logs'
          ? 17
          : 16
        : undefined,
      tags: params.tags
    };

    if (params.mode === 'tail') {
      return {
        ...base,
        limit: 500
      };
    }

    if (params.mode === 'absPeriod' || params.mode === 'relPeriod') {

      const period = periodSettings[params.period]();

      return {
        ...base,
        from: period.from.toISOString(),
        till: period.till.toISOString(),
        limit: params.limit
      };
    }

    if (params.mode === 'range') {
      return {
        ...base,
        from: params.from,
        till: params.till,
        limit: params.limit
      };
    }

    if (params.mode === 'limit') {
      let limit = 1000;
      const paramsLimit = parseInt(params.limit as string, 10);
      if ([ 1000, 5000, 20000 ].includes(paramsLimit)) {
        limit = paramsLimit;
      }

      return {
        ...base,
        limit
      };
    }

    if (params.mode === 'append' || params.mode === 'prepend') {
      return {
        ...base,
        from: params.from,
        till: params.till,
        limit: params.limit
      };
    }

    return {
      ...base
    };

  }

}
