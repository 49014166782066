<div
  *ngFor="let pipelineError of pipelineErrors; let lastItem = last; trackBy: trackByCode;"
  class="__error-info">

  <ul class="__item-layout">
    <li>
      <zui-docs-text>
        <span class="__error-message">{{ pipelineError.error }}</span>
      </zui-docs-text>
    </li>
  </ul>

  <pre *ngIf="pipelineError?.metadata" class="__error-info_meta">{{ pipelineError.metadata | json }}</pre>

  <zui-separator
    *ngIf="!lastItem"
    class="__separator-inner"
    orientation="horizontal"
    position="start"
    size="small"
    spacing="small">
  </zui-separator>

</div>
