export const LARAVEL_MINIMAL_LOCAL_IMPORT_YAML = `#yamlPreprocessor=on
project:
  name: laravel-minimal-local
  tags:
    - zerops-recipe
    - local

services:
  - hostname: app
    type: php-nginx@8.4
    buildFromGit: https://github.com/zeropsio/recipe-laravel-minimal
    enableSubdomainAccess: true
    envSecrets:
      APP_KEY: <@generateRandomString(<32>)>
      APP_DEBUG: true
      APP_ENV: development

  - hostname: db
    type: postgresql@16
    mode: NON_HA
    priority: 10`;
